<template>
  <v-container class="d-flex justify-center" fluid>
    <v-row class="mt-n13" no-gutters>
      <v-col cols="12">
        <v-card class="moholand-shadow rounded-lg">
          <v-card-title>
            دانلود های من
          </v-card-title>

          <v-tabs
            dark
            background-color="white"
            show-arrows
            class="d-flex justify-center"
          >
            <v-tab class="pa-0 ma-0">
              <v-btn
                class="ma-2"
                elevation="0"
                rounded
                :style="selectedCategory !== 0 && 'color: #808080'"
                :color="selectedCategory === 0 ? '#FB6D00' : '#D0D0D0'"
                :dark="selectedCategory === 0"
                @click="selectCategory(0)"
              >
                فایل ها
              </v-btn>
            </v-tab>
            <v-tab class="pa-0 ma-0">
              <v-btn
                class="ma-2"
                elevation="0"
                rounded
                :style="selectedCategory !== 1 && 'color: #808080'"
                :color="selectedCategory === 1 ? '#FB6D00' : '#D0D0D0'"
                :dark="selectedCategory === 1"
                @click="selectCategory(1)"
              >
                دوره ها
              </v-btn>
            </v-tab>
            <v-tab class="pa-0 ma-0">
              <v-btn
                class="ma-2"
                elevation="0"
                rounded
                :style="selectedCategory !== 2 && 'color: #808080'"
                :color="selectedCategory === 2 ? '#FB6D00' : '#D0D0D0'"
                :dark="selectedCategory === 2"
                @click="selectCategory(2)"
              >
                داستان ها
              </v-btn>
            </v-tab>
          </v-tabs>

          <div class="d-flex flex-wrap pa-4">
            <search-bar @onSubmit="onSearchSubmit" />
          </div>

          <MyDataTable
            :headers="headers"
            :items="searchData"
            :loading="loading"
            item-key="id"
            :pagesLength="pagesLength"
            @onPageChanged="GetList"
            :isRemoveEnabled="false"
            :is-edit-enabled="false"
          >
            <template #middleButtonTag="{ row }">
              <v-btn
                v-if="selectedCategory == 0"
                class="mx-1"
                dark
                width="200"
                small
                color="primary"
                @click="onDownload(row)"
              >
                دانلود
              </v-btn>
              <v-btn
                v-else
                class="mx-1"
                dark
                width="200"
                small
                color="primary"
                @click="onShow(row)"
              >
                مشاهده صفحه
              </v-btn>
            </template>
            <template #contentTag="{ row }">
              <td v-if="selectedCategory == 0">{{ row.name }}</td>
              <td v-else>{{ row.title }}</td>
            </template>
          </MyDataTable>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MyDataTable from "../../components/MyDataTable.vue";
import TicketModal from "../../components/Panel/TicketContentModal.vue";
import SearchBar from "../../components/SearchBar.vue";
import MyAxios from "../../constants/MyAxios";
export default {
  components: {
    SearchBar,
    MyDataTable,
    TicketModal,
  },
  created() {
    this.GetList();
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;
      this.items = [];
      this.searchData = [];

      MyAxios.get(
        this.selectedCategory == 0
          ? "/users/purchases"
          : this.selectedCategory == 1
          ? "/users/courses/purchases"
          : "/users/scenarios/purchases",
        {
          params: {
            page: this.$route.query?.page || 1,
            ...this.pathParams,
          },
        }
      )
        .then((response) => {
          this.loading = false;

          let data;
          if (Array.isArray(response.data?.data))
            data = response.data?.data?.filter((val) => !!val);
          else
            data = Object.values(response.data?.data)?.filter((val) => !!val);

          if (this.selectedCategory == 0) this.updateItems(data);
          else {
            this.items = data;
            this.searchData = data;
          }
          console.log("items  ", this.items);
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          console.log(error.response || error);
          this.loading = false;
        });
    },
    onSearchSubmit(name) {
      if (name.length) {
        this.searchData = this.searchData.filter((val) =>
          val.name.includes(name)
        );
      } else {
        this.searchData = this.items;
      }
    },
    onDownload(item) {
      console.log(item);
      this.increaseDownloadCount(item);
      window.open(item?.url || item, "_blank");
    },
    increaseDownloadCount(item) {
      if (this.$store.state.userData?.id)
        MyAxios.post("/downloads/create", {
          user_id: this.$store.state.userData?.id,
          media_id: item?.id || null,
          product_id: item.product_id,
        });
    },
    updateItems(items) {
      this.items = items.reduce(
        (a, current) =>
          current &&
          current.links_media &&
          a.concat(
            current.links_media.map((val) => ({
              id: val.id,
              url: val?.url || val,
              // name: current.name + " " + i || current.title + " " + i || "",
              name: current.name || current.title || "",
              product_id: current.id,
            }))
          ),
        []
      );
      this.searchData = this.items;
    },
    selectCategory(category) {
      this.selectedCategory = category;
      this.GetList();
      if (category == 0)
        this.headers = [
          {
            text: "نام فایل",
            align: "start",
            value: "title",
            groupable: false,
            sortable: false,
          },
          {
            text: "لینک دانلود",
            value: "actions",
            groupable: false,
            sortable: false,
          },
        ];
      else
        this.headers = [
          {
            text: "نام دوره",
            align: "start",
            value: "title",
            groupable: false,
            sortable: false,
          },
          {
            text: "لینک صفحه",
            value: "actions",
            groupable: false,
            sortable: false,
          },
        ];
      this.$router.push({ name: "MyDownloads" });
    },
    onShow(item) {
      if (item.slug) {
        if (this.selectedCategory == 1)
          window.open("/courses/" + item.slug, "_blank");
        else if (this.selectedCategory == 2)
          window.open("/scenarios/" + item.slug, "_blank");
      }
    },
  },
  data() {
    return {
      loading: false,
      page: 1,
      pagesLength: 1,
      selectedCategory: 0,
      path: "/users/purchases",
      pathParams: {
        conditions: {
          user_id: this.$store.state.userData?.id || 0,
        },
      },
      headers: [
        {
          text: "نام فایل",
          align: "start",
          value: "title",
          groupable: false,
          sortable: false,
        },
        {
          text: "لینک دانلود",
          value: "actions",
          groupable: false,
          sortable: false,
        },
      ],
      items: [1, 2, 3, 4],
      searchData: [],
    };
  },
};
</script>

<style></style>
